<template>
  <a-layout-header :style="{ background: '#fff', padding: 0 }">
    <a-row type="flex" justify="end">
      <a-col :span="2">
      <router-link to="/">首页</router-link>
      </a-col>
      <a-col :span="3">
      <AuthAndLogout />
      </a-col>
      <a-col :span="2">
        <ServerStatus />
        </a-col>
    </a-row>
  </a-layout-header>
</template>

<script>
import AuthAndLogout from "@/components/auth/AuthAndLogout.vue"
import ServerStatus from "@/components/public/ServerStatus.vue"
export default {
    components: {
       AuthAndLogout,
    ServerStatus,
  }
}
</script>

<style>

</style>