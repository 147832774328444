<template>
  <a-layout-sider width="200">
    <a-menu
    theme="dark"
      mode="inline"
      :selected-keys="selectedKeys"
      v-model:open-keys="openKeys"
      style="height: 100%"
    >
      <template v-for="item in menuData">
        <a-menu-item
          v-if="!item.children"
          :key="item.path"
          @click="() => $router.push({ path: item.path, query: $route.query })"
        >
          <span>{{ item.title }}</span>
        </a-menu-item>
        <sub-menu v-else :key="item.path" :menu-info="item" />
      </template>
    </a-menu>
  </a-layout-sider>
</template>

<script>
// 递归目录，从路由获取信息生成目录
import SubMenu from "./SubMenu";
export default {
  props: {
    siderMenu: {
      type: String,
      default: ''
    }
  },
  components: {
    "sub-menu": SubMenu,
  },
  watch: {
    "$route.path": function (val) {
      this.selectedKeys = this.selectedKeysMap[val];
      this.openKeys = this.collapsed ? [] : this.openKeysMap[val];
    },
  },
  data() {
    this.selectedKeysMap = {};
    this.openKeysMap = {};
    // 取得路由配置，也可以从route文件中引入。
    const menuData = this.getMenuData(this.$router.options.routes);
    return {
      collapsed: false,
      menuData,
      // 根据当前路由地址取得当前选中项的key
      selectedKeys: this.selectedKeysMap[this.$route.path],
      openKeys: [],
    };
  },
  methods: {
    getMenuData(routes = [], parentKeys = [], selectedKey) {
      const menuData = [];
      for (let item of routes) {
        if (item.siderName === this.siderMenu && !item.hideInMenu) {
          this.openKeysMap[item.path] = parentKeys;
          this.selectedKeysMap[item.path] = [selectedKey || item.path];
          const newItem = { ...item };
          delete newItem.children;
          // 当存在子结点才处理
          if (item.children && !item.hideChildrenInMenu) {
            newItem.children = this.getMenuData(item.children, [
              ...parentKeys,
              item.path,
            ]);
            // 当属于分步表单进行如下处理，最终获得组件的key列表。
          } else {
            this.getMenuData(
              item.children,
              selectedKey ? parentKeys : [...parentKeys, item.path],
              selectedKey || item.path
            );
          }
          menuData.push(newItem);
        } else if (
          // 当根目录不显示但是子目录显示的时候
          !item.hideInMenu &&
          !item.hideChildrenInMenu &&
          item.children
        ) {
          menuData.push(
            ...this.getMenuData(item.children, [...parentKeys, item.path])
          );
        }
      }
      return menuData;
    },
  },
  }
</script>

<style>
</style>