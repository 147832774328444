<template>
  <a-tooltip placement="bottom" 
         v-if="$store.state.alternate_server.server['alternate'] === false"
        >
        <template #title>
          <span v-if="$store.state.user_login_auth.user['authenticated'] === true">主服务器已授权</span>
          <span v-if="$store.state.user_login_auth.user['authenticated'] === false">主服务器未授权</span>
        </template>
        <CheckCircleOutlined :style="mainServerIconStyle"
        />
      </a-tooltip>
      <a-tooltip placement="bottom"
      v-if="$store.state.alternate_server.server['alternate'] === true"
      >
        <template #title>
          <span v-if="$store.state.user_login_auth.user['authenticated'] === true">备用服务器已授权</span>
          <span v-if="$store.state.user_login_auth.user['authenticated'] === false">备用服务器未授权</span>
      </template>
      <ExclamationCircleOutlined :style="alterServerIconStyle"
        />
      </a-tooltip>
</template>

<script>
// 获取服务器状态，判断是否未主服务器，是否处于授权的状态
import {CheckCircleOutlined, ExclamationCircleOutlined} from '@ant-design/icons-vue'
import {defineComponent, onMounted, ref, watch} from 'vue'
import {useStore} from 'vuex'
export default defineComponent({
    components: {
    CheckCircleOutlined,
    ExclamationCircleOutlined
  },
  setup() {
    const alterServerIconStyle = ref("color: orange; font-size: larger")
    const mainServerIconStyle = ref("color: green; font-size: larger")
    const connectErrorStyle = "color: red; font-size: larger"
    const store = useStore()
    onMounted(
      () => {
        if (store.state.user_login_auth.user['authenticated'] !== true) {
          alterServerIconStyle.value = connectErrorStyle
          mainServerIconStyle.value = connectErrorStyle
        }
      }
    )
    watch(() => store.state.user_login_auth.user['authenticated'], function() {
      if (store.state.user_login_auth.user['authenticated'] !== true) {
          alterServerIconStyle.value = connectErrorStyle
          mainServerIconStyle.value = connectErrorStyle
        } else {
          alterServerIconStyle.value = "color: orange; font-size: larger"
          mainServerIconStyle.value = "color: green; font-size: larger"
        }
    })
    return {
      alterServerIconStyle,
      mainServerIconStyle
    }
  }
})
</script>

<style>

</style>