<template>
<!-- 用于递归的子目录 -->
  <a-sub-menu :key="props.menuInfo.path">
    <template #title>
      <span>{{ props.menuInfo.title }}</span>
    </template>
    <template v-for="item in props.menuInfo.children">
      <a-menu-item
        v-if="!item.children"
        :key="item.path"
        @click="
          () =>
            parent.$router.push({ path: item.path, query: parent.$route.query })
        "
      >
        <span>{{ item.title }}</span>
      </a-menu-item>
      <sub-menu v-else :key="item.path" :menu-info="item" />
    </template>
  </a-sub-menu>
</template>
<script>
export default {
  props: ["menuInfo"],
};
</script>
